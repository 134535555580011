<template>
    <div class="learn">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Elections Info</div>
        </div>
        
        <div class="main">
            <div class="box">
                <div class="box_title">Empowering Democracy</div>
                <div class="text">
                    Welcome to our innovative Election Feature! We believe in the power of democracy 
                    and the importance of active participation. With our app, users have the unique 
                    opportunity to cast their votes for the President and Vice President positions, 
                    shaping the leadership of our community.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Monthly Elections</div>
                <div class="text">
                    Every month, we will hold elections to ensure a dynamic and engaging experience for 
                    our users. This regular cadence allows for fresh ideas, perspectives, and leadership. 
                    By holding elections frequently, we provide opportunities for aspiring leaders 
                    to step up and contribute to the growth and development of our platform.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Activity-Based Candidate Selection</div>
                <div class="text">
                    We've devised a fair and transparent process for candidate selection. Our algorithm 
                    automatically identifies eligible candidates based on their activity score from the 
                    previous month. This means that individuals who actively engage and contribute to 
                    the community have the chance to be recognized and potentially hold leadership 
                    positions.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Casting Your Vote</div>
                <div class="text">
                    Participating in the election is simple and accessible for all users. 
                    Within our app, you'll find a dedicated Election section. 
                    Here, you can cast your vote for your preferred candidates. 
                    Each vote counts and plays a vital role in determining the outcome, 
                    ensuring that the collective voice of our community is heard.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Ensuring Fairness and Security</div>
                <div class="text">
                    Maintaining the integrity of the election process is of utmost importance to us. 
                    We've implemented robust measures to prevent fraudulent activities and ensure 
                    fairness. Our system carefully verifies and validates votes, providing a secure 
                    and trustworthy environment for the democratic process. Each user is allowed one 
                    vote per election, promoting fairness and preventing abuse.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Fostering Community Engagement</div>
                <div class="text">
                    The Election Feature goes beyond the act of voting. It creates a vibrant space 
                    for users to come together, share ideas, engage in discussions, and debate the 
                    future direction of our platform. It fosters a sense of community and shared 
                    decision-making, encouraging dialogue and collaboration among our users.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Refreshing Leadership and Promoting Growth</div>
                <div class="text">
                    By conducting monthly elections, we ensure that leadership positions are regularly 
                    reviewed and refreshed. This brings in new perspectives and allows aspiring 
                    leaders to showcase their commitment, dedication, and involvement within the 
                    community. It presents opportunities for growth, influence, and the ability to 
                    shape the platform's future.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Conclusion: Shaping Our Community Together</div>
                <div class="text">
                    Our Election Feature is an essential component of our app, empowering users to 
                    participate in the democratic process and have a say in choosing the President 
                    and Vice President. Through regular elections, fair candidate selection, and a 
                    secure voting system, we foster community engagement, inclusivity, and shared 
                    decision-making. Join us in shaping the future of our platform and making your 
                    voice heard in our monthly elections!
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';

export default {
    components: {
        back
    },
    methods: {
        back() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>

    .learn {
        background-color: white;
    }
    .head {
        text-align: center;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);

        position: relative;
    }
    .back {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .title {
        font-weight: 500;
    }

    .main {
        height: calc(100vh - 51px);
        box-sizing: border-box;
        overflow: auto;
        background-color: #edeef0;
    }
    .l {
        padding: 50px 0px;
    }

    .box {
        margin-top: 10px;
        padding: 15px;
        background-color: white;
    }
    .box_title {
        font-weight: 500;
    }
    .text {
        margin-top: 15px;
        font-size: 15px;
        line-height: 22px;
        color: gray;
    }
</style>